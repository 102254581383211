<template>
  <div class="project-containter">
    <nav-menu></nav-menu>
    <div class="project-body">
      <div class="containter">
        <div class="project-tab">
          <tab :tab="tab" :type="type" @changeValue="changeValue" @defaultValue="defaultValue"></tab>
        </div>
        <!-- 选择标段 -->
        <div v-if="tab == 1">
          <div class="project-search flex-a">
            <el-input v-model="keyword" placeholder="请输入项目名称的关键字" @keyup.enter.native="search"></el-input>
            <div class="btn" @click="search">查询</div>
            <!-- <el-dropdown>
              <span class="el-dropdown-link">
                高级搜索<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </el-dropdown> -->
          </div>
          <div class="order-screen">
            <div class="flex">
              <div class="order-screen-title">来源区域：</div>
              <div :class="city == '' ? 'order-screen-all active' : 'order-screen-all'" @click="changecity('')">全部</div>
              <div class="order-screen-list">
                <div :class="city == item.id ? 'order-screen-item active' : 'order-screen-item'"
                  v-for="item in cityList" :key="item.id" @click="changecity(item.id)">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
            <div class="flex" v-if="countyList.length > 0">
              <div class="order-screen-title"></div>
              <div style="margin-left:70px" :class="county == '' ? 'order-screen-all active' : 'order-screen-all'"
                @click="changecounty('')">全部
              </div>
              <div class="order-screen-list">
                <div :class="county == item.id ? 'order-screen-item active' : 'order-screen-item'"
                  v-for="item in countyList" :key="item.id" @click="changecounty(item.id)">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="order-screen-title">所属公司：</div>
              <div class="order-screen-list">
                <div :class="coId == item.id ? 'order-screen-item active' : 'order-screen-item'" v-for="item in coList"
                  :key="item.id" @click="changeco(item.id)">
                  <span>{{ item.coName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="project-list">
            <div class="project-item flex-a" v-for="(item, index) in list" :key="index">
              <div class="project-item-left">
                <div class="project-item-left-title flex-a">
                  <el-tag :key="item.tendType" type="" size="mini" effect="dark" v-if="item.typeName">
                    {{ item.typeName }}
                  </el-tag>
                  <div class="title" v-if="item.prjName">
                    {{ item.prjName }}
                  </div>
                  <div class="tag" style="margin-left:5px">
                    <el-tag size="mini" v-if="now < item.endTime">申请中
                    </el-tag>
                    <el-tag v-if="now > item.endTime" size="mini" type="warning">投标已截至</el-tag>
                  </div>
                </div>
                <div class="project-item-left-list flex-a">
                  <div class="no">标段编号：{{ item.prjNo }}</div>
                  <div class="start">开标时间：{{ item.tendStDate }}</div>
                  <div class="end">保证金缴纳截止时间：{{ item.prjEndEarDate }}</div>
                </div>
              </div>
              <div class="project-item-middle">
                <div class="money">￥{{ item.prjEarMoney }}</div>
                <div>保证金金额</div>
              </div>
              <div class="project-item-right">
                <el-button :type="now < item.endTime ? 'primary' : 'info'" :disabled="now < item.endTime ? false : true"
                  size="mini" style="width: 190px;" @click="toApply(item)">立即申请{{ name }}</el-button>
              </div>
            </div>
            <el-pagination v-if="list.length > 0" background layout="prev, pager, next" :total="total"
              @current-change="handleCurrentChange">
            </el-pagination>
          </div>
          <div v-if="list.length == 0" style="text-align:center;margin-top:40px;color:#666">
            <img src="./../assets/noData.png" style="width:200px;" alt="">
            <p>暂无数据</p>
          </div>
        </div>
        <!-- 填写信息 -->
        <div v-if="tab == 2">
          <div class="project-info">
            <div class="project-info-title">标段信息</div>
            <div class="project-info-list">
              <div>标段名称：{{ info.tendName }}</div>
              <div>标段编号：{{ info.prjNo }}</div>
              <div>保证金金额：￥{{ info.prjEarMoney }}</div>
              <div>开标时间：{{ info.tendStDate }}</div>
              <div>保证金缴纳截止时间：{{ info.prjEndEarDate }}</div>
            </div>
          </div>
          <div class="project-info">
            <div class="project-info-list">
              <div>金融机构：{{ productList[0] ? productList[0].fnaName : '' }}</div>
            </div>
          </div>
          <div class="project-info">
            <div class="project-info-title">企业信息</div>
            <div class="project-info-list">
              <div>企业名称：{{ coForm.coName }}</div>
              <div>法定代表人：{{ coForm.coLep }}</div>
              <div>统一社会信用代码：{{ coForm.coCode }}</div>
              <div>法人身份证：{{ coForm.coLepCode }}</div>
            </div>
          </div>
          <div class="project-handler">
            <div class="project-handler-title">经办人信息</div>
            <div class="project-handler-form">
              <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
                <el-form-item label="经办人姓名：" prop="name">
                  <el-input v-model="form.name" placeholder="请输入经办人姓名"></el-input>
                </el-form-item>
                <el-form-item label="经办人身份证号：" prop="idCard">
                  <el-input v-model="form.idCard" placeholder="请输入经办人身份证号"></el-input>
                </el-form-item>
                <el-form-item label="经办人邮箱：">
                  <el-input v-model="form.eMail" placeholder="请输入经办人邮箱"></el-input>
                </el-form-item>
                <el-form-item label="经办人手机号：" prop="phone">
                  <el-input v-model="form.phone" placeholder="请输入经办人手机号"></el-input>
                </el-form-item>
                <el-form-item label="业务员：" prop="busPeople">
                  <el-select v-model="form.busPeople" placeholder="请选择" style="width:100%">
                    <el-option v-for="item in buinessList" :key="item.no" :label="item.name + '(' + item.no + ')'"
                      :value="item.no">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="上传担保协议：" prop="url" class="upload-file" v-if="!people.true">
                  <div>
                    <el-upload class="upload-demo" :action="action" :headers="headers" :limit="limit"
                      :before-upload="beroreUpload" accept=".doc,.docx,.pdf,.xlsx" :on-success="handleWordSuccess"
                      :on-remove="removeFile" :file-list="fileList">
                      <i v-if="!(fileList[0] && fileList[0].url)" style="border: 1px dashed #d9d9d9; width: 50px;"
                        class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span v-if="!(fileList[0] && fileList[0].url)"><a :href="people ? people.modelUrl : ''"><i
                          class="el-icon-download"></i> 委托担保服务协议.doc</a></span>
                  </div>
                </el-form-item>
                <el-form-item class="get-code" label="验证码：" prop="code">
                  <!-- <el-input v-model="form.code" placeholder="请输入验证码" autocomplete="off"></el-input>
                  <div class="btn" style="width: 120px;" @click="getCode">{{ codeMsg }}</div> -->
                  <el-input v-model="form.code" placeholder="请输入验证码" style="width: 32%;margin-right: 3%;"></el-input>
                  <el-button v-show="countshow" size="mini" style="font-size: 13px;" @click="getcode">获取验证码</el-button>
                  <el-button v-show="!countshow" size="mini" style="font-size: 13px;" disabled>{{ count
                  }}
                    s</el-button>
                </el-form-item>
              </el-form>
              <div class="project-handler-btns flex-a flex-b">
                <div class="btn-plain" @click="pre(1)">上一步</div>
                <div class="btn" @click="next('form')">下一步</div>
              </div>
              <div class="project-tip" v-if="form.id">您已申请过该项目标段，请前往我的订单查看！</div>
            </div>
          </div>
        </div>
        <!-- 信息确认 -->
        <div v-if="tab == 3">
          <div class="project-info">
            <div class="project-info-title">标段信息</div>
            <div class="project-info-list">
              <div>标书名称：{{ info.tendName }}</div>
              <div>标段编号：{{ info.prjNo }}</div>
              <div>保证金金额：￥{{ info.prjEarMoney }}</div>
              <div>开标时间：{{ info.tendStDate }}</div>
              <div>保证金缴纳截止时间：{{ info.prjEndEarDate }}</div>
            </div>
          </div>
          <div class="project-handler">
            <div class="project-handler-title">经办人信息</div>
            <div class="project-handler-list">
              <div class="project-handler-item flex-a">
                <div class="left"><span>*</span>经办人姓名：</div>
                <div class="right">{{ form.name }}</div>
              </div>
              <div class="project-handler-item flex-a">
                <div class="left"><span>*</span>经办人身份证号：</div>
                <div class="right">{{ form.idCard }}</div>
              </div>
              <div class="project-handler-item flex-a">
                <div class="left"><span>*</span>经办人邮箱：</div>
                <div class="right">{{ form.eMail }}</div>
              </div>
              <div class="project-handler-item flex-a">
                <div class="left"><span>*</span>经办人手机号：</div>
                <div class="right">{{ form.phone }}</div>
              </div>
            </div>
            <div class="project-handler-btns apply flex-a flex-b">
              <div class="btn-plain" @click="pre(2)">上一步</div>
              <div class="btn" @click="subApply">提交申请</div>
            </div>
          </div>
        </div>
        <!-- 选择担保公司 -->
        <div v-if="tab == 4" class="product-list-box">
          <div>等待出函</div>
          <!-- <el-row :gutter="12">
            <el-col :span="6" v-for="item in productList" :key="item.id">
              <el-card shadow="hover" :class="item.id == id ? 'active' : ''" @click.native="selectProduct(item.id)">
                <div><img :src="item.finaUrl" alt=""></div>
                <div>{{ item.name }}</div>
              </el-card>
            </el-col>
          </el-row> -->
          <!-- <div class="product-list-btn">
            <div class="btn" @click="submit">提交</div>
          </div> -->
        </div>
        <!-- 保费支付 -->
        <div v-if="tab == 5">
          <div class="project-succ">
            <!-- <div><i class="el-icon-success" style="color: #52C41A;font-size: 60px;"></i></div>
            <div>意见提交成功</div> -->
            <p class="describe" v-if="apiRes.payData">
              <img src="@/assets/wx_app.svg" alt="" v-show="pay == 'WX_NATIVE'"><!-- 微信图标 -->
              <img src="@/assets/ali_app.svg" alt="" v-show="pay == 'ALI_QR'">
              <span>{{ pay == 'WX_NATIVE' ? '请使用微信"扫一扫"扫码支付' : pay == 'ALI_QR' ? '请使用支付宝"扫一扫"扫码支付' : '' }}</span>
            </p>
            <p v-if="apiRes.money">金额：{{ apiRes.money }}</p>
            <div><img v-if="apiRes.payDataType == 'codeImgUrl'" :src="apiRes.payData" alt="" /></div>

          </div>
          <div class="project-select flex-a flex-b">
            <span class="left"></span>
            <span>请选择缴费方式</span>
            <span class="right"></span>
          </div>
          <div class="project-pay flex-a">
            <!-- <div :class="pay == 'ALI_QR' ? 'active' : ''" @click="changePay('ALI_QR')"><img src="@/assets/ali-pay.svg"
                alt="">
            </div> -->
            <div :class="pay == 'WX_NATIVE' ? 'active' : ''" @click="changePay('WX_NATIVE')"><img
                src="@/assets/wx-pay.png" alt="">
            </div>
          </div>
          <div class="project-pay-btn flex-a flex-b">
            <div class="btn" @click="toPay">立即支付</div>
            <div class="btn-plain">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <pay-test-modal ref="payTestModal"></pay-test-modal>
    <foot></foot>
  </div>
</template>

<script>
import Tab from '../components/tab.vue'
import { getList, addInfo, pay, applyState, applyStateNoToken, getCode, getCascaderList, getListNoToken, getCityListByToken, getCityList, getCountyListByToken, getBusMap, getCountyList, getProductList, subProduct, getProListByPrjNo } from '../lib/api/project'
import PayTestModal from './PayTestModal' // 二维码对话框组件
import { delImg } from '@/lib/api/attestation'
import { getAccountState } from '@/lib/api/attestation'
import { getCoList, getCoInfo } from '@/lib/api/attestation'
import baseUrl from '@/lib/utils/baseUrl'
export default {
  name: 'projectindex',
  components: { Tab, PayTestModal },
  data() {
    return {
      buinessList: [],
      activeName: '',
      coList: [],
      coId: -1,
      now: 0,
      id: -1,
      type: 0,
      city: '',
      county: '',
      pay: 0,
      source: 0,
      cityList: [],//来源区域
      countyList: [],
      productList: [],//担保公司列表
      headers: { Authorization: "Bearer " + localStorage.getItem('token') },
      limit: 1,
      action: baseUrl + '/buiness/coword/uploadFile',
      fileList: [],
      people: {},
      payType: [
        {
          id: 1,
          name: '房屋及市政工程'
        },
        {
          id: 2,
          name: '交通工程'
        },
        {
          id: 3,
          name: '水利工程'
        },
      ],//交易类型
      sourceType: [
        {
          id: 1,
          name: '网招'
        },
        {
          id: 2,
          name: '非网招'
        }
      ],//网招类型
      options: [],
      tab: 1,
      code: '',
      name: '',
      disabled: false,
      codeMsg: '获取验证码',
      keyword: '',
      total: 0,
      list: [],
      info: {},
      prodId: 0,//选择产品id
      form: {
        name: '',
        idCard: '',
        phone: '',
        eMail: '',
        code: '',
        busPeople: ''
      },
      coForm: {},
      timer: null,
      rules: {
        name: [
          { required: true, message: '请填经办人姓名', trigger: 'blur' }
        ],
        idCard: [
          { required: true, message: '请填经办人身份证号', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证格式不正确' }
        ],
        phone: [
          { required: true, message: '请填写经办人手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
        ],
        eMail: [
          { required: true, message: '请填写经办人邮箱', trigger: 'blur' },
          { pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, message: '邮箱格式不正确' }
        ],
        code: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ],
        url: [
          {
            required: true, validator: (rule, value, callback) => {
              if (!this.hasFmt) {
                callback(new Error('请上传担保协议'));
              } else {
                callback();
              }
            }, message: '请上传担保协议', trigger: 'change'
          }
        ],
        busPeople: [
          { required: true, message: '请选择业务员', trigger: 'change' }
        ]
      },
      pay: '',
      apiRes: {},
      t: 60,
      countshow: true,
      count: ''
    }
  },
  created() {
    setInterval(() => {
      var date = new Date()
      this.now = date.getTime()
    }, 1000)
    if (this.$route.query.type) {
      this.type = Number(this.$route.query.type)
    }
    this.getList({ pageNum: 1 })//获取项目标段列表
    if (localStorage.getItem('token')) {
      getCityListByToken().then(res => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    } else {
      getCityList().then(res => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    }
    this.getCoList()
  },
  methods: {
    //获取账号认证状态
    getAccountState() {
      getAccountState().then(res => {
        if (res.code == 200 && res.data && res.data.id) {
          if (res.data.state == 4) {
            this.tab = 2
            this.coForm = res.data
            this.coForm.coAddress = this.coForm.coAddress.split('/')
          } else {
            this.$message({
              message: '公司信息在审核中，不能开函！',
              type: 'warning'
            });
          }
        }
      })
    },
    //获取公司列表
    getCoList() {
      getCoList(localStorage.getItem('userId')).then(res => {
        if (res.code == 200) {
          this.coList = res.data.list
          this.coId = res.data.id
          // this.getCoInfo(res.data.id+'')
        }
      })
    },
    // 获取验证码
    async getcode() {
      if (this.form.phone == '') {
        this.$message({
          message: '请先填写手机号',
          type: 'warning'
        });
      } else {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.countshow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.countshow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
        this.codesuccess() // 调用接口获取验证码
      }
    },
    //来源区域
    changecity(n) {
      this.city = n
      if (n != '') {
        this.getCountys(n)
      } else {
        this.countyList = []
      }
      this.county = ''
      this.getList()
    },
    //所属公司
    changeco(n) {
      this.coId = n
      this.getCoInfo(n)
    },
    // 获取公司列表信息
    getCoInfo(id) {
      getCoInfo(id).then(res => {

      })
    },
    //县级
    changecounty(n) {
      this.county = n
      this.getList()
    },
    //交易类型
    changePay(n) {
      this.pay = n
    },
    //网招类型
    changeSource(n) {
      this.source = n
    },
    //选择担保公司
    selectProduct(id) {
      this.id = id
    },
    //tab默认值
    defaultValue(name, value) {
      this.name = name
      this.code = value
    },
    //tab下拉框带过来的值
    changeValue(name, value) {
      this.name = name
      this.code = value
      if (localStorage.getItem('token')) {
        this.applyState()
      } else {
        this.applyStateNoToken
      }
    },
    //获取经办人信息--登录
    applyState() {
      applyState({ prjNo: this.info.prjNo || 'null', type: this.code }).then(res => {
        if (res.code == 200 && res.data && res.data.id) {
          this.$message({
            message: '您已申请过该项目标段，请前往我的订单查看！',
            type: 'warning'
          });
        }
        this.people = res.data
      })
    },
    //获取经办人信息--未登录
    applyStateNoToken() {
      applyStateNoToken({ prjNo: this.info.prjNo || 'null', type: this.code }).then(res => {
        if (res.code == 200 && res.data && res.data.id) {
          this.$message({
            message: '您已申请过该项目标段，请前往我的订单查看！',
            type: 'warning'
          });
        }
      })
    },
    beroreUpload(file) {
      if (this.people && this.people.true == true) {
        this.$message({
          message: '该公司已经在本平台上传过担保协议，无需上传！',
          type: 'warning'
        });
        return false
      }
    },
    //附件上传
    handleWordSuccess(res, file, fileList) {
      console.log(file)
      if (res.code == 200) {
        console.log(res.url)
        this.fileList.push({ name: file.name, url: res.url })
        this.form.url = res.url
      }
    },
    //删除文件
    removeFile(file, fileList) {
      delImg({ url: file.url }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '删除成功！',
            type: 'success'
          });
          this.fileList = []
        }
      })
    },
    //提交
    submit() {
      if (this.id < 0) {
        this.$message({
          message: '请选择担保公司再提交！',
          type: 'warning'
        });
      } else {
        subProduct({ id: this.id, prjNo: this.info.prjNo }).then(res => {
          if (res.code == 200) {
            this.$alert('提交成功！请前往我的订单查看！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.push({ path: '/attestation', query: { tab: 'order' } })
              }
            });
          }
        })
      }
    },
    //获取县级列表
    getCountys(id) {
      if (localStorage.getItem('token')) {
        getCountyListByToken(id).then(res => {
          if (res.code == 200) {
            this.countyList = res.data
          }
        })
      } else {
        getCountyList(id).then(res => {
          if (res.code == 200) {
            this.countyList = res.data
          }
        })
      }
    },
    //tab切换
    // changeTab(index) {
    //   this.tab = index
    // },
    //获取项目标段列表
    getList(params) {
      if (localStorage.getItem('token')) {
        getList(Object.assign({}, params, {
          pageSize: 10, tendName: this.keyword, tendJur: this.city, tendCity: this.county
        })).then(res => {
          if (res.code == 200) {
            this.total = res.total
            res.rows.forEach(item => {
              item.endTime = new Date(item.prjEndEarDate).getTime()
            })
            this.list = res.rows
          }
        })
      } else {
        getListNoToken(Object.assign({}, params, {
          pageSize: 10, tendName: this.keyword, tendJur: this.city, tendCity: this.county
        })).then(res => {
          if (res.code == 200) {
            this.total = res.total
            res.rows.forEach(item => {
              item.endTime = new Date(item.prjEndEarDate).getTime()
            })
            this.list = res.rows
          }
        })
      }
    },
    //查询
    search() {
      this.getList({ pageNum: 1 })//获取项目标段列表
    },
    //60s倒计时
    time() {
      var t = this.t
      if (t > 0) {
        t--
      } else {
        t = 0
      }
      this.t = t
    },
    codesuccess() {
      getCode({ phone: this.form.phone }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '已发送',
            type: 'success'
          });
        }
      })
    },
    //立即申请
    toApply(item) {
      if (!localStorage.getItem('token')) {
        this.$alert('请前往登录!', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      } else {
        this.info = item
        //查看立即申请状态
        if (localStorage.getItem('token')) {
          applyState({ prjNo: item.prjDatNo, type: this.code }).then(res => {
            if (res.code == 200 && res.data && res.data.id) {
              this.$message({
                message: '您已申请过该项目标段，请前往我的订单查看！',
                type: 'warning'
              });
            } else {
              if (res.code == 500) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'error'
                });
              }
              this.getAccountState()//获取企业信息
              this.getProductList(item.prjDatNo)
              this.getBusMap()
            }
            this.people = res.data
            if (res.data && res.data.true == true) {
              this.form.url = res.data.url
            }
          })
        } else {
          applyStateNoToken({ prjNo: item.prjDatNo, type: this.code }).then(res => {
            if (res.code == 200 && res.data && res.data.id) {
              this.$message({
                message: '您已申请过该项目标段，请前往我的订单查看！',
                type: 'warning'
              });
            } else {
              if (res.code == 500) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'error'
                });
              }
              this.getAccountState()//获取企业信息
              this.getProductList(item.prjDatNo)
              this.getBusMap()
            }
          })
        }
      }
    },
    //获取业务员列表
    getBusMap() {
      getBusMap().then(res => {
        if (res.code == 200) {
          this.buinessList = res.data
        }
      })
    },
    getProductList(prjNo) {
      getProListByPrjNo({ code: this.code, prjNo: prjNo }).then(res => {
        if (res.code == 200) {
          this.productList = res.data
        }
      })
    },
    //立即支付
    toPay() {
      pay(
        {
          // jsapi 默认使用聚合二维码支付
          wayCode: this.pay, // 支付方式
          prjNo: this.info.prjNo,
        }
      ).then(res => {
        // this.$nextTick(() => {
        //   this.$refs.payTestModal.showModal(this.pay, res.data) // 打开弹窗
        // })
        this.apiRes = res.data
      })
    },
    //上一步
    pre(n) {
      this.tab = n
    },
    //下一步
    next(formName) {
      if (this.form.id) {
        this.$message({
          message: '您已申请过该项目标段，请勿重复申请！',
          type: 'warning'
        });
      } else {
        // if (this.form.url) {
        //   this.$refs[formName].clearValidate(['url']);
        // }
        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     var param = Object.assign({}, this.form, { proNo: this.info.prjDatNo }, { type: this.code }, { prodId: this.productList[0].id }, { true: this.people.true }, { url: this.people.true == true ? this.people.url : this.fileList[0].url })
        //     addInfo(param).then(res => {
        //       if (res.code == 200) {
        //         this.$message({
        //           message: res.msg,
        //           type: 'success'
        //         });
        //         this.tab = 3
        //       } else {
        //         this.$message.error(res.msg);
        //       }
        //     })
        //   } else {
        //     return false;
        //   }
        // });
        const p1 = new Promise(resolve => {
          this.$refs[formName].validateField("name", err => {
            resolve(err);
          });
          resolve();
        });
        const p2 = new Promise(resolve => {
          this.$refs[formName].validateField("idCard", err => {
            resolve(err);
          });
          resolve();
        });
        const p3 = new Promise(resolve => {
          this.$refs[formName].validateField("phone", err => {
            resolve(err);
          });
          resolve();
        });
        const p4 = new Promise(resolve => {
          this.$refs[formName].validateField("busPeople", err => {
            resolve(err);
          });
          resolve();
        });
        const p5 = new Promise(resolve => {
          this.$refs[formName].validateField("code", err => {
            resolve(err);
          });
          resolve();
        });
        Promise.all([p1, p2, p3, p4, p5])
          .then(result => {
            console.log(result)
            var param = Object.assign({}, this.form, { proNo: this.info.prjDatNo }, { type: this.code }, { prodId: this.productList[0].id }, { true: this.people.true }, { url: this.people.true == true ? this.people.url : this.fileList[0].url })
            addInfo(param).then(res => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: 'success'
                });
                this.tab = 3
              } else {
                this.$message.error(res.msg);
              }
            })
          })
      }
    },
    //提交申请
    subApply() {
      this.tab = 4
      // getProductList({ code: this.code }).then(res => {
      //   if (res.code == 200) {
      //     this.productList = res.data
      //     this.tab = 4
      //   }
      // })
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getList({
        pageNum: val
      })
    },
    //选择支付方式
    changePay(str) {
      this.pay = str
    }
  }
}
</script>
<style scoped lang="less">
.project-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .project-body {
    padding: 20px 0;

    .containter {
      .project-tab {
        border-bottom: 1px solid #E8E8E8;
        padding: 10px 30px 30px;
      }

      .project-search {
        padding: 20px 100px;

        .btn {
          margin: 0 20px 0 30px;
          letter-spacing: 3px;
        }

        .el-dropdown {
          white-space: nowrap;
        }

        .el-dropdown-link {
          cursor: pointer;
          color: #409EFF;
        }

        .el-icon-arrow-down {
          font-size: 12px;
        }
      }

      .project-list {
        .project-item {
          border-radius: 5px;
          padding: 20px;
          box-shadow: 0 0 10px #E6E6E6;
          margin-bottom: 15px;

          .project-item-left {
            width: 72%;

            .project-item-left-title {
              color: #272727;
              margin-bottom: 15px;
              white-space: nowrap;

              .title {
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }

            .project-item-left-list {
              color: #5A5A5A;
              font-size: 14px;

              div {
                white-space: nowrap;
                text-align: left;
              }

              .no {
                width: 37%;
              }

              .start {
                width: 28%;
              }

              .end {
                width: 35%;
              }
            }
          }

          .project-item-middle {
            color: #FF6600;
            width: 10%;
            font-size: 14px;
            white-space: nowrap;
            text-align: center;

            .money {
              font-weight: bold;
              font-size: 20px;
            }
          }

          .project-item-right {
            width: 18%;
          }
        }
      }

      .project-info {
        margin-top: 20px;
        border-radius: 5px;
        padding: 20px 0 20px 20px;
        box-shadow: 0 0 10px #E6E6E6;

        .project-info-title {
          color: #272727;
          font-weight: bold;
          margin-bottom: 15px;
          font-size: 16px;
        }

        .project-info-list {
          padding-left: 30px;
          color: #272727;
          font-size: 14px;

          div:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }

      .project-handler {
        .project-select {
          margin: 10px 0 0 20px;

          span {
            color: #272727;
            font-weight: bold;
            font-size: 16px;
          }

          .el-cascader {
            width: 30%;
          }
        }

        .project-handler-title {
          color: #272727;
          font-weight: bold;
          padding-left: 20px;
          margin: 10px 0 30px;
          font-size: 16px;
        }

        .project-handler-form {
          width: 50%;
          margin: 0 auto;

          .project-tip {
            color: red;
            text-align: center;
            font-size: 15px;
            margin-top: 20px;
          }
        }

        .project-handler-btns {
          padding: 0 33%;
        }

        .project-handler-btns.apply {
          padding: 0 41%;
        }

        .project-handler-list {
          padding-left: 24%;

          .project-handler-item {
            font-size: 14px;
            color: #272727;
            margin-bottom: 30px;

            .left {
              width: 15%;
              text-align: right;
              white-space: nowrap;
              margin-right: 10px;

              span {
                color: #FF2727;
              }
            }

            .right {
              text-align: left;
            }
          }
        }
      }

      .project-succ {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #5A5A5A;
        margin: 50px 0 30px;
      }

      .project-select {
        color: #5A5A5A;

        .left,
        .right {
          display: inline-block;
          height: 1px;
          background: #E8E8E8;
        }

        .left {
          width: 5%;
        }

        .right {
          width: 83%;
        }

        span {
          white-space: nowrap;
        }
      }

      .project-pay {
        padding: 30px 0 50px 50px;

        div {
          width: 150px;
          margin-right: 20px;
          box-sizing: border-box;
          border: 1px solid transparent;

          img {
            width: 100%;
          }
        }

        div.active {
          border-color: #1890FF;
          background: #F5F5F5;
          border-radius: 5px;
        }

        div:hover {
          cursor: pointer;
        }
      }

      .project-pay-btn {
        padding: 0 42%;
      }
    }
  }
}

.describe {
  img {
    width: 30px;
    height: 25px;
  }
}

.order-screen {
  margin-bottom: 20px;
  // padding: 0 40px;
  color: #666;
  font-size: 14px;

  .flex {
    margin-bottom: 10px;
  }

  .order-screen-title {
    margin-right: 30px;
    white-space: nowrap;
    font-weight: bold;
  }

  .order-screen-all {
    margin-right: 20px;
    text-align: center;
    width: 40px;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
  }

  .order-screen-all:hover {
    cursor: pointer;
  }

  .order-screen-item {
    margin-right: 40px;
    margin-bottom: 10px;
    display: inline-block;

    span {
      padding: 2px 5px;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .active {
    background: #FF4822;
    color: #fff;
  }
}

.product-list-box {
  margin-top: 20px;

  div {
    text-align: center;

    img {
      width: 100%;
    }
  }

  .el-col {
    margin-bottom: 10px;
  }

  .el-card:hover {
    cursor: pointer;
  }

  .el-card {
    height: 165px;
  }

  .active {
    border-color: #FF2727;
  }

  .product-list-btn {
    div {
      margin: 20px auto 0;
    }
  }
}

.upload-file {
  position: relative;

  span {
    position: absolute;
    right: 40%;
    top: 50%;
    transform: translateY(-50%);

    a {
      color: #1890FF;
    }
  }

  span:hover {
    cursor: pointer;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

.upload-demo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
