<template>
  <div class="tab flex-a">
    <div class="title">
      <!-- <span class="text" v-if="!value">{{ type == 0 ? '投标保函' : type == 1 ? '投标保险' : '' }}</span> -->
      <!-- <span class="text" v-if="value">{{ value.label }}</span> -->
      <!-- <el-select v-model="value" placeholder="" @change="changeValue" :disabled="tab > 2">
        <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code">
        </el-option>
      </el-select> -->
      <div class="txt">请选择产品类型</div>
      <el-cascader :options="options" :show-all-levels="false" v-model="code" placeholder=""
        :props="{ children: 'list', value: 'code', label: 'name' }" @change="handleChange"></el-cascader>
    </div>
    <div class="tab-list flex-a flex-b">
      <div :class="tab == index + 1 ? 'tab-item flex-a active' : 'tab-item flex-a'" v-for="(item, index) in list"
        :key="index" @click="changeTab(index + 1)">
        <div class="tab-item-num">{{ index + 1 }}</div>
        <div class="tab-item-info">
          <div><img :src="tab == index + 1 ? item.active : item.src" alt=""></div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCascaderList, getMapTreeNoToken, getMapTree } from '../lib/api/project'
export default {
  name: 'tab',
  components: {},
  props: {
    tab: Number,
    type: Number,
  },
  data() {
    return {
      options: [],
      value: '',
      code: 'DZDBBH',
      name: '电子投标担保保函',
      list: [
        {
          src: require('@/assets/select.svg'),
          active: require('@/assets/select-active.svg'),
          title: '选择标段'
        },
        {
          src: require('@/assets/edit.svg'),
          active: require('@/assets/edit-active.svg'),
          title: '填写信息'
        },
        {
          src: require('@/assets/sure.svg'),
          active: require('@/assets/sure-active.svg'),
          title: '信息确认'
        },
        {
          src: require('@/assets/submit.svg'),
          active: require('@/assets/submit-active.svg'),
          title: '出函'
        },
        // {
        //   src: require('@/assets/pay.svg'),
        //   active: require('@/assets/pay-active.svg'),
        //   title: '保费支付'
        // }
      ]
    }
  },
  watch: {
    // type: function () {
    //   this.getList()
    // }
  },
  created() {
    // this.getList()
    if (localStorage.getItem('token')) {
      getMapTree().then(res => {
        if (res.code == 200) {
          this.options = res.data
          this.init(res.data, this.code)
        }
      })
    } else {
      getMapTreeNoToken().then(res => {
        if (res.code == 200) {
          this.options = res.data
          this.init(res.data, this.code)
        }
      })
    }
    // this.$emit("defaultValue", this.name, this.code)
    if (this.$route.query.code) {
      this.code = this.$route.query.code
      this.init(this.options, this.$route.query.code)
      // this.$emit("changeValue", this.name,this.$route.query.code)
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    init(arr, code) {
      arr.forEach(element => {
        element.list.forEach(item => {
          if (item.code == code[1] || item.code == code) {
            this.name = item.name
          }
        })
      });
      this.$emit("changeValue", this.name, code)
    },
    handleChange(value) {
      console.log(value);
      this.init(this.options, value[1])
      // this.$emit("changeValue", this.name,value)
    },
    changeTab(index) {
      this.$emit('changeTab', index)
    },
    // getList() {
    //   getCascaderList({ type: this.type }).then(res => {
    //     if (res.code == 200) {
    //       this.options = res.data
    //       var code = ''
    //       if (this.$route.query.code) {
    //         res.data.forEach(element => {
    //           if (element.code == this.$route.query.code) {
    //             this.value = element.name
    //             code = this.$route.query.code
    //           }
    //         });
    //       } else {
    //         this.value = res.data[0].name
    //         code = res.data[0].code
    //       }
    //       this.$emit("defaultValue", code)
    //     }
    //   })
    // },
  },
  computed: {

  }
}
</script>

<style lang="less">
.tab {
  .title {
    color: #1890FF;
    font-weight: bold;
    margin-right: 50px;
    font-size: 20px;
    white-space: nowrap;
    position: relative;

    .txt {
      font-size: 16px;
      font-weight: bold;
      color: #272727;
      padding-left: 15px;
    }

    .el-input__inner {
      border: none !important;
      color: #1890FF;
      font-weight: bold;
      font-size: 20px;
    }

    .text {
      position: absolute;
      left: 0;
      z-index: 99;
    }
  }

  .tab-list {
    width: 80%;
  }

  .tab-item {
    .tab-item-num {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #CCCCCC;
      text-align: center;
      line-height: 30px;
      color: #CCCCCC;
      margin-right: 10px;
    }

    .tab-item-info {
      color: #8D8D8D;

      div {
        white-space: nowrap;
      }

      img {
        width: 40px;
      }
    }
  }

  .tab-item.active {
    .tab-item-num {
      color: #fff;
      background: #1890FF;
      border-color: #1890FF;
    }

    .tab-item-info {
      color: #000000;

      img {
        color: #000000;
      }
    }
  }

  // .tab-item:hover{
  //   cursor: pointer;
  // }
  .el-cascader{
    width: 100%;
  }
  i{
    font-weight: bold;
    color:#333;
  }
}
</style>
